<template>
  <mobile-screen
    v-if="helpOnline[slugToUse]"
    :header="true"
    screen-class="gray-bg fluid help-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="occupancy-header icon-hea1"
        :noHelpOnline="true"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ helpOnline[slugToUse].name }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section class="help-content-wrapper" v-if="helpOnline[slugToUse].content">
      <div v-html="helpOnline[slugToUse].content"></div>
    </section>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";

export default {
  name: "HelpOnline",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  created() {
    if (!this.helpOnline && !this.helpOnline[this.slugToUse]) {
      this.$router.push({ name: this.backLinkName });
    }
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
  },
  computed: {
    ...mapState("helpOnline", ["helpOnline"]),
    slugToUse() {
      if (this.slug) {
        return this.slug;
      }
      return this.$route.query.slug;
    }
  },
  props: {
    backLinkName: {
      type: String
    },
    slug: {
      type: String
    }
  }
};
</script>
